import { useRef } from 'react'
import { Editor } from '@tinymce/tinymce-react';
import useImage from '../../hooks/useImage';
import { imageURL } from '../../libs/webservice';
import { imageTypes } from '../../libs/Methods';

export const RichTextEditor = ({ onChange, setData }) => {
  const editorRef = useRef(null);
  const { uploadImage } = useImage();
  return (
    <>
      <Editor
        apiKey='91pnoz0ts6ex44uw3277xa5gzm8sb3bes2xvetn2uujl72ya'
        textareaName="article"
        onInit={(evt, editor) => editorRef.current = editor}
        onEditorChange={(event) => onChange(event)}
        value={setData}
        init={
          {
            selector: 'textarea#basic-example',
            height: 500,
            plugins: [
              'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
              'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
              'insertdatetime', 'media', 'table', 'help', 'wordcount'
            ],
            toolbar: 'undo redo | blocks | ' +
              'bold italic backcolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }',
            file_picker_types: 'image,file',
            file_picker_callback: (callback, value, meta) => {
              const input = document.createElement('input');
              input.type = 'file';
              input.accept = 'image/*,.pdf';
              input.onchange = async () => {
                const file = input.files[0];
                const res = await uploadImage(file, imageTypes.blog)
                const imgURL = imageURL + res.data.url
                  callback(imgURL)
              };
              input.click();
          },
          }
        }
      />
    </>
  );
};