import { Button, Divider, Grid, IconButton } from '@mui/material'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import useBlogger from '../../hooks/useBlogger';
import { Storege } from '../../libs/Methods';
import blog_image from '../../assets/images/blog_image.png';

const Blogger = () => {

    const navigate = useNavigate()
    const { getBlogger, loadMore } = useBlogger()
    const [allBlogs, setAllBlogs] = useState([])
    const [nextPageToken, setNextPageToken] = useState('')

    // const createBlog = async () => {

    //     const response = await createDraftBlog()

    //     Storege.SET(DRAFTKEY, response.data.id)
    //     navigate("/blog-management")
    // }

    const hendalLoadMore = () => {
        (async () => {
            const loadMoreData = await loadMore(nextPageToken)
            setAllBlogs([...allBlogs, ...loadMoreData.data.items])
            if ('nextPageToken' in loadMoreData.data) {
                setNextPageToken(loadMoreData.data.nextPageToken)
            } else {
                setNextPageToken(null)
            }
        })()
    }

    const hendalSaveDB = (url) => {
        const parts = url.split("/");
        const result = parts.slice(3).join("/");
        navigate(`/blogger-blog?url=${result}`)
    }

    useEffect(() => {
        (async () => {
            const getAllBlog = await getBlogger()
            console.log('getAllBlog', getAllBlog.data)
            setAllBlogs(getAllBlog.data.items)
            if ('nextPageToken' in getAllBlog.data) {
                setNextPageToken(getAllBlog.data.nextPageToken)
            } else {
                setNextPageToken(null)
            }
        })()
    }, [])

    return (
        <>
            <div className='page_header_main'>
                <h1 className='page_title'>Blog</h1>
                {/* <Button variant='contained' onClick={createBlog} >Add Blog</Button> */}
            </div>
            {
                allBlogs.map((item) => (
                    <div className='blog_card'>
                        <Grid container spacing={0}>
                            <Grid item xs={1.8}>
                                <img src={item?.images?.length ? item?.images[0].url : blog_image} alt='' width='220px' height='120px' className='blog_img' />
                            </Grid>
                            <Grid item xs={8}>
                                <div className='card_detail'>
                                    <h1>{item.title}</h1>
                                    <p className='description'>{item.description}</p>
                                </div>
                            </Grid>
                            <Grid item xs={1}>
                                {item.platform}
                            </Grid>
                            <Divider orientation="vertical" flexItem />
                            <Grid item xs={1.1}>
                                <div className='card_action'>
                                    {/*<IconButton aria-label="delete" onClick={() => navigate('/view-blog')}>
                                        <RemoveRedEyeOutlinedIcon />
                                    </IconButton>
                                    <IconButton aria-label="delete">
                                        <DeleteIcon />
                                    </IconButton>
                                    <IconButton aria-label="delete" onClick={() => navigate(`/blog/edit/${item.url}/${item.uuid}`)}>
                                        <EditOutlinedIcon />
                                    </IconButton>*/}
                                    <Button variant='contained' onClick={() => hendalSaveDB(item.url)} >Save to DB</Button>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                ))
            }
            {nextPageToken && <Button variant='contained' onClick={() => hendalLoadMore()} >Load MOre</Button>}
        </>
    )
}

export default Blogger