import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Routes from './routes/Routes';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './style/Blog.css'
import { Storege } from './libs/Methods';
import { useEffect } from 'react';

function App() {

  useEffect(() => {
    const platform = Storege.GET("platform");
    if (platform == undefined || platform == null) {
      Storege.SET("platform", "thorient")
    }
  }, [])

  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  );
}

export default App;
