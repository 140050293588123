import { API_KEY, BLOG_ID } from "./Methods"

export const BLOG_API = {
    CREATE_DRAFT : '/v1/blog-service/create-draft-blog',
    GET_BLOG : '/v1/blog-service/get-blogs',
    GET_BLOG_BY_URL : '/v1/blog-service/get-single-blog',
    CREATE_BLOG : '/v1/blog-service/create-blog',
    CREATE_BLOG_CATEGORY : '/v1/blog-service/create-blog-category',
    GET_BLOG_CATEGORY : '/v1/blog-service/get-blog-category',
    ADD_BLOG_CATEGORY : '/v1/blog-service/create-blog-category',
}

export const IMAGE_API = {
    UPLOAD_IMAGE: '/v1/image-service/upload'
}

export const BLOGGER = {
    GET_LIST: `https://blogger.googleapis.com/v3/blogs/${BLOG_ID}/posts?key=${API_KEY}&fetchImages=true&fetchBodies=false`,
    GET_SINGLE : `https://blogger.googleapis.com/v3/blogs/${BLOG_ID}/posts/bypath?key=${API_KEY}`
}

export const POLL_API = {
    DELETE_POLL: '/v1/poll-service/delete-poll',
}

