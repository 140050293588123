import { TextField, Button } from '@mui/material';
import React, { useState } from 'react';
import usePoll from '../../hooks/usePoll';

function Poll() {
  const [pollId, setPollId] = useState();
  const { deletePoll } = usePoll();
  return (
    <div>
      <TextField
        label="Poll ID"
        variant="standard"
        onChange={(e) => {
          setPollId(e.target.value)
        }}
      />
      <Button variant='contained' onClick={() => {
        deletePoll(pollId)
      }}>
        Delete
      </Button>
    </div>
  )
}

export default Poll