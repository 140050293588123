import { BLOGGER, BLOG_API } from "../libs/API"
import API from "../libs/webservice"

const useBlogger = () => {

    const getBlogger = async () => {
        return await API.get(BLOGGER.GET_LIST)
    }
    const loadMore = async (token) => {
        return await API.get(`${BLOGGER.GET_LIST}&pageToken=${token}`)
    }
    const getSingleBlogger = async (path) => {
        return await API.get(`${BLOGGER.GET_SINGLE}&path=/${path}`)
    }
    return {getBlogger, loadMore, getSingleBlogger}

}

export default useBlogger