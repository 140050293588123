import React, { useEffect, useState } from 'react';
import { Button, Grid, IconButton, Divider } from '@mui/material';
import blog_image from '../../assets/images/blog_image.png';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useNavigate } from 'react-router-dom';
import useBlog from '../../hooks/useBlog';
import { DRAFTKEY, Storege } from '../../libs/Methods';

const Blog = () => {

    const navigate = useNavigate()
    const { createDraftBlog, getBlog } = useBlog()
    const [allBlogs, setAllBlogs] = useState([])

    const createBlog = async () => {

        const response = await createDraftBlog()

        Storege.SET(DRAFTKEY, response.data.id)
        navigate("/blog-management")
    }

    useEffect(() => {

        (async () => {
            const getAllBlog = await getBlog({admin: 'admin', platform: Storege.GET('platform')})
            setAllBlogs(getAllBlog.data.data)
        })()

    }, [])


    return (
        <>
            <div className='page_header_main'>
                <h1 className='page_title'>Blog</h1>
                <Button variant='contained' onClick={createBlog} >Add Blog</Button>
            </div>
            {
                allBlogs.map((item) => (
                    <div className='blog_card'>
                        <Grid container spacing={0}>
                            <Grid item xs={1.8}>
                                <img src={item?.image_url ? item?.image_url : blog_image} alt='' width='220px' height='120px' className='blog_img' />
                            </Grid>
                            <Grid item xs={8}>
                                <div className='card_detail'>
                                    <h1>{item.title}</h1>
                                    <p className='description'>{item.description}</p>
                                </div>
                            </Grid>
                            <Grid item xs={1}>
                                {item.platform}
                            </Grid>
                            <Divider orientation="vertical" flexItem />
                            <Grid item xs={1.1}>
                                <div className='card_action'>
                                    <IconButton aria-label="delete" onClick={() => navigate('/view-blog')}>
                                        <RemoveRedEyeOutlinedIcon />
                                    </IconButton>
                                    <IconButton aria-label="delete">
                                        <DeleteIcon />
                                    </IconButton>
                                    <IconButton aria-label="delete" onClick={() => navigate(`/blog/edit/${item.url}/${item.uuid}`)}>
                                        <EditOutlinedIcon />
                                    </IconButton>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                ))
            }
        </>
    )
}

export default Blog