import axios from "axios";

export const baseURL = 'https://prod-thorient.belbytes.com/api'
// export const baseURL = 'http://localhost:8000/api'

export const imageURL = 'https://bms.belbytes.com'

const API = axios.create({ baseURL })

axios.interceptors.response.use((response) => {
    return response
}, (error) => {
    return Promise.reject(error)
})

export default API;