import React, { useEffect } from 'react'
import AuthLayout from '../layout/AuthLayout'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import Dashboard from './dashboard/Dashboard'
import Layout from '../layout/Layout'
import Login from './login/Login'
import Error from './error/Error'
import Blog from './blog/Blog'
import BlogManagement from './blog/BlogManagement'
import ViewBlog from './blog/ViewBlog'
import Blogger from './blogger/Blogger'
import BloggerBlog from './blogger/BloggerBlog'
import Poll from './poll/Poll'

const Index = () => {
    const location = useLocation();
    const navigate = useNavigate();
    let { pathname } = { ...location };

    const getToken = async () => {
        const token = localStorage.getItem("dGVzdCBwYXNzd29yZAo!=");
        if (token) {
            navigate('/dashboard')
        } else {
            navigate('/')
        }
    }

    useEffect(() => {
        getToken();
    }, [])

    if (["/"].includes(pathname)) {
        return (
            <Layout>
                <Routes >
                    <Route path='/' element={<Login />} />
                </Routes>
            </Layout>
        )
    } else if (["/dashboard", "/blog", "/blog-management", "/view-blog", "/blog/edit", "/blogger", '/blogger-blog', '/poll'].includes(pathname) || pathname.includes('edit')) {
        return (
            <AuthLayout>
                <Routes >
                    <Route path='/dashboard' element={<Dashboard />} />
                    <Route path='/blog' element={<Blog />} />
                    <Route path='/blog/edit/:url/:id' element={<BlogManagement />} />
                    <Route path='/blog-management' element={<BlogManagement />} />
                    <Route path='/view-blog' element={<ViewBlog />} />
                    <Route path='/blogger' element={<Blogger />} />
                    <Route path='/blogger-blog' element={<BloggerBlog />} />
                    <Route path='/poll' element={<Poll />} />
                </Routes>
            </AuthLayout>
        )
    } else {
        return (
            <Routes>
                <Route path='*' element={<Error />} />
            </Routes>
        )
    }
}

export default Index