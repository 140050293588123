import React from 'react'
import API from '../libs/webservice'
import { BLOG_API } from '../libs/API'

const useBlog = () => {
    const createDraftBlog = async () => {
        return await API.post(BLOG_API.CREATE_DRAFT, {})
    }

    const createBlog = async (body) => {
        return await API.post(BLOG_API.CREATE_BLOG, body)
    }
    const getBlog = async (body) => {
        return await API.post(BLOG_API.GET_BLOG, body)
    }

    const getBlogByURL = async (body) => {
        return await API.post(BLOG_API.GET_BLOG_BY_URL, body)
    }
    const getBlogCategory = async (body) => {
        return await API.post(BLOG_API.GET_BLOG_CATEGORY, body)
    }
    const addBlogCategory = async (body) => {
        return await API.post(BLOG_API.ADD_BLOG_CATEGORY, body)
    }
    return { createDraftBlog, createBlog, getBlog, getBlogByURL, getBlogCategory, addBlogCategory}
}

export default useBlog