import { Button } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const ViewBlog = () => {
    const navigate = useNavigate()
    return (
        <>
            <div className='page_header_main'>
                <h1 className='page_title'>View Blog</h1>
                <Button variant='contained' onClick={() => {}} >Add View Blog</Button>
            </div>
        </>
    )
}

export default ViewBlog