import { useState, useEffect } from 'react';
import useBlogger from '../../hooks/useBlogger';

const BloggerBlog = () => {

    const {getSingleBlogger} = useBlogger()
    const [bloggerData, setBloggerData] = useState()
    const params = new URLSearchParams(window.location.search);
    const url = params.get('url');

    console.log('url', url)
    console.log('bloggerData', bloggerData)

    useEffect(() => {
        (async () => {
            const singleBlogger = await getSingleBlogger(url)
            console.log('singleBlogger', singleBlogger)
            setBloggerData(singleBlogger.data)
        })()
    }, [])


    return (
        <>
        {/* <div>{bloggerData?.content}</div> */}
        <div dangerouslySetInnerHTML={{__html: bloggerData?.content}} />
        </>
    )
}

export default BloggerBlog