import React, { useEffect, useState } from 'react';
import { Button, Grid, InputLabel, TextField, Switch, FormControlLabel, Select, MenuItem, Dialog } from '@mui/material';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import { RichTextEditor } from '../../component/Editor/RichText';
import useBlog from '../../hooks/useBlog';
import useImage from '../../hooks/useImage';
import { imageURL } from '../../libs/webservice';
import { DRAFTKEY, Platforms, Storege, imageTypes } from "../../libs/Methods";
import { useNavigate, useParams } from 'react-router-dom';

const BlogManagement = () => {

    const navigate = useNavigate()
    const { url, id } = useParams()
    const { createBlog, getBlogByURL, getBlogCategory, addBlogCategory } = useBlog();
    const { uploadImage } = useImage()

    const [blogData, setBlogData] = useState({
        title: "",
        url: "",
        description: "",
        article: "",
        image_url: "",
        category: "",
        status: false,
    })

    const [platform, setPlatform] = useState("");
    const [category, setCategory] = useState([]);
    const [addCategory, setAddCategory] = useState(false);


    const saveBlog = async () => {

        const body = {
            id: id && url ? id : Storege.GET(DRAFTKEY),
            title: blogData?.title,
            url: blogData?.url,
            description: blogData?.description,
            category: blogData?.category,
            status: blogData?.status ? "PUBLISH" : "DRAFT",
            article: blogData?.article,
            img_url: blogData?.image_url,
            platform: platform,
        }

        await createBlog(body).then(() => { Storege.REMOVE(DRAFTKEY) })
        navigate(-1)

    }

    const handleOnChange = async (event) => {
        if (event.target.name === "image_url") {
            const res = await uploadImage(event.target.files[0], imageTypes.blog)
            setBlogData({ ...blogData, [event.target.name]: imageURL + res.data.url })
        } else if (event.target.name === "status") {
            if (blogData?.status) {
                setBlogData({ ...blogData, status: false })
            } else {
                setBlogData({ ...blogData, status: true })
            }
        } else if (event.target.name === "platform") {
            setPlatform(event.target.value)
        } else {
            setBlogData({ ...blogData, [event.target.name]: event.target.value })
        }
    }

    useEffect(() => {
        if (url) {
            (async () => {
                try {
                    const temp = await getBlogByURL({ url: url, type: 'admin' });
                    const { data } = temp
                    setBlogData({
                        ...blogData,
                        id: id,
                        title: data.title,
                        url: data.url,
                        description: data.description,
                        article: data.article,
                        image_url: data.image_url,
                        category: data.category,
                        status: data.status === 'DRAFT' ? false : true,
                    })
                } catch (err) {
                    console.log("🚀 ~ file: BlogManagement.js:78 ~ err:", err)
                }

            })()
        }
    }, [url])

    const platformVar = Storege.GET("platform");
    useEffect(() => {
        if (platformVar !== undefined && platformVar !== null) {
            setPlatform(platformVar);
            getCategory();
        }
    }, [])

    useEffect(() => {
        if (blogData.title && !id && !url) {
            let temp = blogData.title.split(" ").join("-").toLowerCase()
            setBlogData({ ...blogData, url: temp })
        }
    }, [blogData.title])

    useEffect(() => {
        if (blogData.category === 'add') {
            setAddCategory(true);
        }
    }, [blogData.category])

    const getCategory = async () => {
        const cat = await getBlogCategory({ platform: platformVar });
        setCategory(cat.data.category);
    }

    const getRTEData = (data) => {
        setBlogData({ ...blogData, article: data })
    }


    return (
        <>
            <div className='page_header_main'>
                <h1 className='page_title'>Blog Management</h1>
                <div style={{ display: 'flex', gap: '10px' }}>
                    <Button variant='contained' onClick={saveBlog} >Save Blog</Button>
                    <Button variant='outlined' onClick={() => navigate(-1)} >Cancel</Button>
                </div>
            </div>
            <div className=''>
                <Grid container spacing={2}>
                    <Grid item xs={9}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <InputLabel className='input_label'>Title</InputLabel>
                                <TextField
                                    hiddenLabel
                                    id="outlined-size-small"
                                    size="medium"
                                    sx={{ width: '100%' }}
                                    value={blogData.title}
                                    onChange={handleOnChange}
                                    name='title'
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InputLabel className='input_label'>Url</InputLabel>
                                <TextField
                                    id="outlined-size-small"
                                    size="medium"
                                    sx={{ width: '100%' }}
                                    name='url'
                                    value={blogData.url}
                                    onChange={handleOnChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel className='input_label'>Description</InputLabel>
                                <textarea
                                    className='input_textarea'
                                    rows={5}
                                    name='description'
                                    value={blogData.description}
                                    onChange={handleOnChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel className='input_label'>Article</InputLabel>
                                <RichTextEditor setData={blogData.article} onChange={(e) => getRTEData(e)} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <InputLabel className='input_label'>Thumbnail</InputLabel>
                                <div className='image_upload'>
                                    <label htmlFor='image_upload' >
                                        {blogData?.image_url ? <img src={blogData?.image_url} width={"100%"} height={"100%"} /> :
                                            <>
                                                <AddPhotoAlternateOutlinedIcon style={{ color: '#cccccc' }} />
                                                <p>Upload</p>
                                            </>
                                        }
                                    </label>
                                    <input hidden id='image_upload' type='file' name='image_url' onChange={handleOnChange} />
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <InputLabel className='input_label'>category</InputLabel>
                                <Select
                                    id="outlined-size-small"
                                    size="medium"
                                    sx={{ width: '100%' }}
                                    name='category'
                                    value={blogData.category}
                                    onChange={handleOnChange}
                                    placeholder='Category'
                                >
                                    <MenuItem value='add'><h4>+Add new</h4></MenuItem>
                                    {
                                        category.map((ele) => (
                                            <MenuItem value={ele.category}>{ele.category}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel className='input_label'>Platform</InputLabel>
                                <Select
                                    id="outlined-size-small"
                                    size="medium"
                                    sx={{ width: '100%' }}
                                    name='platform'
                                    value={platform}
                                    onChange={handleOnChange}
                                >
                                    {
                                        Platforms.map((ele) => (
                                            <MenuItem value={ele}>{ele}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    value={blogData.status}
                                    control={<Switch color="primary" name='status' onChange={handleOnChange}
                                        checked={blogData.status} value={blogData.status} />}
                                    label="Status"
                                    labelPlacement="start"
                                // onChange={handleOnChange}                                    
                                // checked={true}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </div>

            <Dialog
                open={addCategory}
                onClose={() => setAddCategory(false)}
                fullWidth
            >
                <InputLabel className='input_label'>Category</InputLabel>
                <TextField
                    id="outlined-size-small"
                    size="medium"
                    sx={{ width: '100%' }}
                    name='category'
                    value={blogData.category}
                    onChange={handleOnChange}
                />
                <Grid container lg={6}>
                    <Grid item lg={3}>
                        <Button
                            onClick={async () => {
                                const temp = await addBlogCategory({ platform: Storege.GET("platform"), category: blogData.category });
                                setBlogData({ ...blogData, category: temp.data.category });
                                getCategory()
                                setAddCategory(false)
                            }}
                        >Save</Button>
                    </Grid>
                    <Grid item lg={3}>
                        <Button
                            onClick={() => setAddCategory(false)}
                        >Cancel</Button>
                    </Grid>
                </Grid>
            </Dialog>
        </>
    )
}

export default BlogManagement