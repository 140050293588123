import React from 'react'
import Pages from '../pages/index'

const Routes = () => {
  return (
    <>
      <Pages />
    </>
  )
}

export default Routes