import React, { useEffect, useState } from 'react'
import { styled, useTheme } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { Button, Grid, InputLabel, TextField, Switch, FormControlLabel, Select, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Platforms, Storege } from '../libs/Methods';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Header = ({ open, closeSidebar }) => {

    const [platform, setPlatform] = useState("thorient")

    useEffect(() => {
        const platform = Storege.GET("platform");
        if (platform !== undefined && platform !== null) {
            setPlatform(platform)
        }
    }, [])


    const handleOnChange = (event) => {
        setPlatform(event.target.value)
        Storege.SET("platform", event.target.value)
        window.location.reload()
    }

    return (
        <>
            <AppBar className='header_main' position="fixed" open={open} style={{ backgroundColor: '#3e54ab' }}>
                <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={closeSidebar}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <div>
                        <Select
                            id="outlined-size-small"
                            size="small"
                            sx={{ width: '200px' }}
                            name='platform'
                            value={platform}
                            onChange={handleOnChange}
                        >
                            {
                                Platforms.map((ele) => (
                                    <MenuItem value={ele}>{ele}</MenuItem>
                                ))
                            }
                        </Select>
                    </div>
                </Toolbar>
            </AppBar>
        </>
    )
}

export default Header