import React from 'react'
import API from '../libs/webservice'
import { POLL_API } from '../libs/API'

const usePoll = () => {
    const deletePoll = async (id) => {
        return await API.post(POLL_API.DELETE_POLL, { poll_id: id })
    }

    return {
        deletePoll
    }
}

export default usePoll