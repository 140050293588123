import axios from 'axios';

export const baseURL = ' https://bms.belbytes.com';

const useImage = () => {
    const uploadImage = async (file, type) => {
        const formData = new FormData()
        formData.append('type', type)
        formData.append('media', file)

        return await API.post("/upload", formData)
    }

    const API = axios.create({
        baseURL
    })

    axios.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        return Promise.reject(error);
    });

    return { uploadImage }
}

export default useImage