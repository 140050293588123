import React from 'react'
import Sidebar from './Sidebar'
import Header from './Header'
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));





const AuthLayout = ({ children }) => {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                
                <Header open={open} closeSidebar={() => setOpen(true)} />
                <Sidebar open={open} closeSidebar={() => setOpen(false)}/>
                <Box component="main" sx={{ flexGrow: 1, p: 3, backgroundColor:'#ecf2ff', height:'100vh', overflow:'auto' }}>
                    <DrawerHeader />
                    <div>{children}</div>
                </Box>
            </Box>
            {/* <div className="authlayout_main">
                <Sidebar />
                <div className='authlayout_inner'>
                    <Header />
                </div>
            </div> */}
        </>
    )
}

export default AuthLayout